import axios from 'axios'
import qs from 'querystring'
import { message } from 'ant-design-vue'

const baseURL = process.env.NODE_ENV !== 'production' ? 'http://192.168.0.72:8080/vfauth_war/' : '../vfauth'
// const baseURL = process.env.NODE_ENV !== 'production' ? 'http://192.168.0.72:8080/vfauth_war' : '../vfauth'

export default ({ Vue, store, router }) => {
  let instance = axios.create({
    baseURL,
    timeout: 600000
  })

  instance.interceptors.request.use(
    config => {
      if (store.state.user && store.state.user.token) {
        config.headers['Authorization'] = store.state.user.token
      } else {
        if (location.href.indexOf('/mobile/') > 0) {
          config.headers['x-authorization'] = '-x-mobile'
        }
      }
      // console.log('config', config, location)
      if (store.state.user && store.state.user.id) {
        if (config.params === void 0) {
          config.params = {}
        }
        config.params.u = store.state.user.id
      }
      return config
    },
    err => {
      return Promise.reject(err)
    }
  )

  // http response 拦截器
  instance.interceptors.response.use(
    response => {
      // 授权登录信息错误返回登录页面
      if (response.data && response.data.code === 401) {
        if (router.currentRoute.path !== 'login') {
          message.destroy()
          message.warning('登陆信息已过期，请重新登陆!')
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          })
        }
      }
      if (response.headers['x-token']) {
        // console.log('x-token', response.headers['x-token'])
        store.dispatch('setToken', response.headers['x-token'])
      }
      return response
    },
    error => {
      // console.log(error)
      if (error.response) {
        message.destroy()
        if (error.response.status === 401) {
          message.warning('登陆信息已过期，请重新登陆!')
          // 401 清除token信息并跳转到登录页面
          store.dispatch('logout')
          // 只有在当前路由不是登录页面才跳转
          if (router.currentRoute.path !== 'login') {
            router.replace({
              path: '/login',
              query: { redirect: router.currentRoute.fullPath }
            })
          } else {
            router.replace({
              path: '/login'
            })
          }
        } else if (error.response.status === 500) {
          message.warning('服务器响应错误')
        }
        return Promise.reject(error.response.data)
      } else if (error.message.indexOf('timeout') > -1) {
        message.warning('网络超时')
      } else {
        message.warning('网络错误')
      }
      return Promise.reject(error)
    }
  )

  const http = {
    get(uri, params) {
      return instance.get(uri, {
        params
      })
    },
    post(uri, params, headers) {
      return instance.post(uri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...headers
        }
      })
    },
    delete(uri, params) {
      return instance.delete(uri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    put(uri, params) {
      return instance.put(uri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    upload(uri, params) {
      return instance.post(uri, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    raw(uri, params) {
      return instance.post(uri, JSON.stringify(params), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    export(uri, params) {
      return instance.post(uri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob'
      })
    },
    all: instance.all,
    spread: instance.spread
  }

  Vue.prototype.$axios = axios
  Vue.prototype.$http = http

  return http
}
