<template>
  <a-config-provider :locale="zhCN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
// import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default {
  name: 'app',
  data() {
    return {
      zhCN
    }
  }
}
</script>