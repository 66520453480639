<template>
  <div class="page-login">
    <!-- <div class="login-bg2"></div> -->
    <div class="login-bg" id="loginBg"><div id="output"></div></div>
    <!-- <transition name="fade" mode="out-in" v-on:before-enter="beforeEnter"> -->
    <div class="login-form">
      <div class="absolute login-logo">
        <img src="~@/assets/img/logo.png" />
      </div>
      <div class="form__cover"></div>
      <!-- <div class="form__loader">
        <div class="spinner active">
          <svg class="spinner__circular" viewBox="25 25 50 50">
            <circle class="spinner__path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10"></circle>
          </svg>
        </div>
      </div> -->
      <div class="form__content">
        <p class="text-center" style="font-size: 24px;font-family:pmzd;">
          万丰小方工作平台
        </p>
        <a-form layout="horizontal" aria-autocomplete="off">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="用户名"
          >
            <a-input v-model="userName" @pressEnter="login">
              <a-icon slot="prefix" style="color:rgba(0,0,0,.25)" type="user" />
            </a-input>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="密码"
          >
            <a-input type="password" v-model="password" @pressEnter="login">
              <a-icon slot="prefix" style="color:rgba(0,0,0,.25)" type="lock" />
            </a-input>
          </a-form-item>
        </a-form>
        <div class="text-center" style="margin-top: 15px;">
          <a-button
            type="primary"
            :loading="logining"
            @click="login"
            class="text-white"
            style="font-size: 14px; background: #BFA06A;"
            >登&nbsp;&nbsp;&nbsp;陆</a-button
          >
        </div>
      </div>
    </div>
    <div class="choose-wrapper relative-position">
      <div class="absolute login-logo">
        <img src="~@/assets/img/logo.png" />
      </div>
      <div class="choose-container flex items-center justify-around">
        <!-- <template> -->
        <div class="choose-item" v-for="sys in systems" :key="sys.topic">
          <a-spin :spinning="sys.loading" tip="正在登入系统...">
            <a-icon
              slot="indicator"
              type="loading"
              style="font-size: 24px"
              spin
            />
            <div
              class="choose-item__img"
              title="点击进入"
              @click="turnToPage(sys)"
            >
              <!-- <img :src="`./img/${sys.topic}.png`"/> -->
              <img src="~@/assets/img/zt.png" v-if="sys.topic === 'zt'" />
              <img
                src="~@/assets/img/fof.png"
                v-else-if="sys.topic === 'fof'"
              />
              <img
                src="~@/assets/img/oms.png"
                v-else-if="sys.topic === 'oms'"
              />
              <img
                src="~@/assets/img/deer.png"
                v-else-if="sys.topic === 'deer'"
              />
              <img
                src="~@/assets/img/factor.png"
                v-else-if="sys.topic === 'FactorTest'"
              />
              <img
                src="~@/assets/img/wfims.png"
                v-else-if="sys.topic === 'wfims'"
              />
            </div>
          </a-spin>
          <p
            style="font-size: 20px;font-family:pmzd;margin-bottom: 0;color: #fff8e1;"
          >
            {{ sys.name }}
          </p>
          <!-- <a-spin :spinning="sys.loading"> -->
        </div>
        <!-- </template> -->
      </div>
      <div class="text-center">
        <a-space>
          <span
            class="hover-line text-brown"
            style="font-size: 16px;text-decoration: underline;"
            @click="showPwdModal"
            >修改密码</span
          >
          <span
            class="hover-line text-brown"
            style="font-size: 16px;text-decoration: underline;"
            @click="returnLogin"
            >注销并返回登录</span
          >
        </a-space>
      </div>
    </div>
    <!-- </transition> -->
    <a-modal :confirmLoading="pwdUpdating" :width="400" :visible="pwdModal" @cancel="pwdModal = false" @ok="onSavePwd" centered okText="确认" title="修改密码" destroyOnClose>
      <a-form>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
              <span slot="label"><b class="text-red z-mr-sm">*</b>新密码</span>
              <a-input-password v-model="newpwd" placeholder="输入密码" v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]" ref="newPassword"/>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="24">
            <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" label="姓名">{{ pwdModalData.realName }}</a-form-item>
          </a-col> -->
          <a-col :span="24">
            <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
              <span slot="label"><b class="text-red z-mr-sm">*</b>确认密码</span>
              <a-input-password v-model="newpwd2" placeholder="输入密码" v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]" ref="newPassword2"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
// var CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      userName: "",
      password: "",
      newpwd: "",
      newpwd2: "",
      logining: false,
      loading: true,
      pwdUpdating: false,
      pwdModal: false,
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 17 },
        sm: { span: 18 }
      },
      systems: [],
      token: this.$route.query.t,
      showLogin: true
    };
  },
  computed: {
    user: vm => vm.$store.state.user.user
  },
  methods: {
    login() {
      console.log("login");
      let userName = this.userName;
      let password = this.password;
      if (userName.trim() === "" || !password) {
        this.$message.warn("请输入用户名和密码");
        return false;
      }
      // this.showLogin = false
      // password = CryptoJS.MD5(userName + password).toString()
      this.logining = true;
      this.$http
        .post("/login", { userName, password })
        .then(({ data }) => {
          if (data.success) {
            let { token, systems } = data.data;
            this.systems = systems.map(item => ({ ...item, loading: false }));
            this.token = token;
            this.$nextTick(() => {
              if (this.systems.length) {
                // this.turnToPage(this.systems[0]);
                // } else if (this.systems.length > 1) {
                this.showLogin = false;
                document.body.classList.add("system-loaded");
              } else {
                this.$message.destroy();
                this.$message.warning("当前用户没有系统权限");
              }
            });
          } else {
            this.$message.destroy();
            this.$message.warning(data.msg);
          }
          this.logining = false;
        })
        .catch(ex => {
          console.log("error", ex);
          this.logining = false;
        });
    },
    beforeEnter: function(el) {
      console.log("before enter", el);
    },
    turnToPage(system) {
      // window.open(system.url)
      let token = this.token;
      system.loading = true;
      this.$http
        .post("/sysLogin", { topic: system.topic }, { Authorization: token })
        .then(({ data }) => {
          if (data.success) {
            // console.log('ok', system)
            location.href = `${system.url}?token=${token}`;
            // location.href = `http://192.168.0.189:3000/#/ssologin?token=${token}`
          } else {
            this.$message.destroy();
            this.$message.warning(data.msg);
          }
          system.loading = false;
        })
        .catch(ex => {
          system.loading = false;
        });
    },
    returnLogin() {
      let token = this.token;
      this.$http
        .post("/logout", {}, { Authorization: token })
        .then(({ data }) => {
          if (data.success) {
            // console.log('ok', system)
          } else {
            // this.$message.destroy()
            // this.$message.warning(data.msg)
            console.log("logout failed", data);
          }
          document.body.classList.remove("system-loaded");
        })
        .catch(ex => {
          console.log("logout error", ex);
          document.body.classList.remove("system-loaded");
        });
    },
    showPwdModal() {
      this.newpwd = ''
      this.newpwd2 = ''
      this.$nextTick(() => {
        this.pwdModal = true
      })
    },
    onSavePwd() {
      let vm = this
      let newpwd = this.newpwd
      let newpwd2 = this.newpwd2
      vm.$message.destroy()
      if (!newpwd || newpwd.length < 6) {
        vm.$message.warning('密码不能为空且不少于6个字符')
        vm.$refs.newPassword.focus()
        return false
      }
      if (newpwd2 != newpwd) {
        vm.$message.warning('两次输入的密码不一致')
        vm.$refs.newPassword2.focus()
        return false
      }
      vm.pwdUpdating = true
      vm.$http
        .post('/updatePwd', { pwd: newpwd }, { Authorization: vm.token })
        .then(({ data }) => {
          if (data.success) {
            vm.pwdModal = false
            Modal.warning({
              cancelText: '',
              title: '密码修改成功，请重新登录',
              okText: '重新登录',
              closable: false,
              onOk: () => {
                vm.returnLogin()
              },
            })
            // vm.load()
          } else {
            vm.$message.destroy()
            vm.$message.warning(data.msg)
          }
          vm.pwdUpdating = false
        })
        .catch((ex) => {
          vm.$message.success('操作失败')
          vm.pwdUpdating = false
        })
    }
  },
  created() {
    // setTimeout(function() {
    //   document.body.classList.add('on-start')
    //   setTimeout(function() {
    //     document.body.classList.add('document-loaded')
    //   }, 1800)
    // }, 20)
    let vm = this;
    if (vm.token) {
      vm.$http
        .post("/system/user/sysQuery", { a: 3 }, { Authorization: vm.token })
        .then(({ data }) => {
          if (data.success) {
            let systems = data.data;
            vm.systems = systems.map(item => ({ ...item, loading: false }));
            vm.$nextTick(() => {
              // if (vm.systems.length === 1) {
                // vm.turnToPage(vm.systems[0])
              //   document.body.classList.add("document-loaded");
              //   document.body.classList.add("system-loaded");
              //   vm.returnLogin();
              // } else if (vm.systems.length > 1) {
              if (vm.systems.length > 0) {
                vm.showLogin = false;
                document.body.classList.add("document-loaded");
                document.body.classList.add("system-loaded");
              } else {
                vm.$message.destroy();
                vm.$message.warning("当前用户没有系统权限，请重新登录。");
                document.body.classList.remove("system-loaded");
              }
            });
          } else {
            vm.$message.destroy();
            vm.$message.warning(data.msg);
            setTimeout(function() {
              document.body.classList.add("document-loaded");
            }, 600);
          }
          // system.loading = false
        })
        .catch(ex => {
          // system.loading = false
        });
    } else {
      setTimeout(function() {
        document.body.classList.add("document-loaded");
      }, 600);
    }
  },
  mounted() {
    // let vector = new Victor('loginBg', 'output')
    // vector(['#5B472F', '#BF9E69']).set()
    FSS("loginBg", "output");
  }
};
</script>

<style lang="stylus">
.page-login
  position fixed
  top 0
  right 0
  left 0
  bottom 0
  overflow hidden
  // background linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%)
  display flex
  align-items center
  justify-content center

  .login-bg
    position absolute
    left 0
    top 0
    height 100%
    width 100%
    // background url('~@/assets/img/login_bg.jpg') no-repeat transparent
    // background url('~@/assets/img/bg.jpg') no-repeat transparent
    // background url('~@/assets/img/login_bg.png') 0% 0% / 100%
    opacity 0.95
    // z-index 1
    background #ffdc9e
    // background-size cover

  .login-form
    display flex
    align-items center
    justify-content center
    position relative
    width 300px
    // height 300px
    flex-shrink 0
    padding 40px 20px
    border-radius 5px
    z-index 1
    // opacity 0

  .form__loader
    display flex
    position absolute
    left 0
    top 0
    height 100%
    width 100%
    justify-content center
    align-items center
    z-index -4
    transition all 0.5s ease

  .ant-form-item-label
    line-height 39.9999px !important

.login-bg2
  position absolute
  left 0
  top 0
  height 100%
  width 100%
  background #D0B588
  // background url('~@/assets/img/login_bg.jpg') no-repeat transparent
  // background url('~@/assets/img/login_bg.png') no-repeat transparent
  // background url('~@/assets/img/login_bg.png') 0% 0% / 100%
  opacity 0.8
  background-size cover
.choose-wrapper
  display none
  // transform translateX(200px)
  opacity 0
  transition all 2s ease
  transform scale(0)
.spinner
  position relative
  margin auto
  width 50px
  height 50px
  transition all 0.2s ease 0s

.spinner__circular
  animation rotate 1.5s linear infinite
  animation-play-state paused
  transform-origin center center
  position absolute
  width 100%
  height 100%
  top 0
  left 0
  margin auto

.spinner__path
  stroke-dasharray 1, 200
  stroke-dashoffset 0
  animation dash 1.3s ease forwards 0.5s
  opacity 0
  stroke-linecap round
  stroke #E5BC67
  animation-play-state running

.form__cover
  position absolute
  left 0
  top 0
  height 100%
  width 100%
  z-index -4
  border-radius 20px
  overflow hidden
  transition all 0.3s ease 0.8s
  box-shadow 0 0 0 0 rgba(0, 0, 0, 0)

.form__cover:after
  content ''
  position absolute
  left 0
  top 0
  height 100%
  width 100%
  // background #e1c68f
  background #ffffff
  opacity .9
  z-index -4
  border-radius 50%
  transition all 1.5s ease 0.3s
  transform scale(0)

.form__cover:before
  content ''
  position absolute
  left 0
  top 0
  height 100%
  width 100%
  background #F6F0E1
  z-index -5
  border-radius 50%
  transition all 0.5s ease
  transform scale(0)

.form__content
  text-align center
  display flex
  justify-content center
  flex-direction column
  position relative
  opacity 0
  transform translateY(10px)
  transition all 0.5s ease 0.7s
  // color #fff

  // .ant-form-item-label > label
  //   color #fff
.login-logo
  opacity 0
  top -100px
  left 50%
  transform translateX(-50%)
  transition all 1s ease 0.3s
body.on-start
  background-color #E3BA66
body.on-start .form__cover:before
  transform scale(0.15)
body.document-loaded .form__content
  opacity 1
body.document-loaded .login-form .login-logo
  opacity 1
  top -50px
body.document-loaded .choose-wrapper .login-logo
  opacity 1
  top -80px
  left 50%
  transform translateX(-50%)
body.document-loaded .form__loader
  transform scale(0)
  opacity 0
  visibility hidden
body.document-loaded .form__cover
  box-shadow 0 20px 50px rgba(0, 0, 0, 0.3)

body.document-loaded .form__cover:after
  transform scale(2)


body.document-loaded.system-loaded .login-form
  display none
body.document-loaded.system-loaded .choose-wrapper
  display block
  opacity 1
  transform scale(1)
  animation slidefade 1s ease

body.document-loaded .form__cover:before
  // transition opacity 0.3s ease 0.8s, -webkit-transform 2s ease
  transition transform 2s ease, opacity 0.3s ease 0.8s
  // transition transform 2s ease, opacity 0.3s ease 0.8s, -webkit-transform 2s ease
  transform scale(2)
  opacity 0

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  50% {
    stroke-dasharray: 40, 200;
    opacity: 1;
  }
  100% {
    stroke-dasharray: 125, 200;
    opacity: 1;
  }
}
@keyframes slidefade {
  0% {
    opacity: 0;
    // transform: translateX(200px);
    transform: scale(0);
  }
  // 30% {
  //   opacity: 0.3;
  //   transform: scale(0.3);
  // }
  // 50% {
  //   opacity: 0.5;
  //   transform: translateX(100px);
  //   transform: scale(0.5);
  // }
  50% {
    opacity: 0.9;
    // transform: translateX(90px);
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    // transform: translateX(0);
    transform: scale(1);
  }
}
.choose-container
  position relative
  z-index 1
  width 1060px
  padding 20px 0
.choose-item
  width 320px
  text-align center
  position relative
  .ant-spin
    color #fff
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-text
    text-shadow 0 0px 1px #fff
.choose-item__img
  border 2px solid #E6C35C
  font-size 0
  line-height 0
  box-shadow 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px rgba(0,0,0,.14), 0 1px 10px rgba(0,0,0,.12)
  border-radius 4px
  cursor pointer
  box-sizing border-box
  &:hover
    border 2px solid #FF5722
    box-shadow 0 12px 14px -1px rgba(0,0,0,.2), 0 4px 5px rgba(0,0,0,.14), 0 1px 10px rgba(0,0,0,.12)
  img
    width 100%
    height 160px

#output
  opacity 0.3
  width 100%
  height 100%
  transition all 400ms ease-in-out
</style>
