import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import { message } from "ant-design-vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  }
];

window.chkVersion = version => {
  let _vflogin_version =
    localStorage._vflogin_version || window._vflogin_version;
  window._vflogin_version = version;
  if (_vflogin_version !== version) {
    localStorage._vflogin_version = version;
    message.loading("系统更新", 1, () => {
      // 刷新页面
      location.reload(true);
    });
  }
};

const compareVersion = function() {
  if (!window._fof_version) {
    var versionScript = document.createElement("script");
    versionScript.src = "js/version.js?v=" + Date.now();
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(versionScript, s);
  }
};

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  compareVersion();
  next();
});
export default router;
