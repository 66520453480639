import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './boot/antdv'

import _axios from './boot/axios'
_axios({ Vue, router, store })

import './style/index.styl'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
